import React from "react";
import NinjaGirl from "../../assets/img/ninjaGirl.png";
import { Col, Row } from "antd";
import SearchAI from "../../assets/svg/SearchAI";

const HeyNinja = () => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] w-full xl:w-[1073px] mx-2 bg-[#EAEDF6] rounded-lg overflow-hidden md:pr-8 pt-5 md:pt-10">
        <Row className="w-full">
          <Col
            span={24}
            md={{ span: 11 }}
            className="flex items-center justify-center md:items-end md:justify-start w-full z-10"
          >
            {/* <div className="relative"> */}
            {/* <div className="absolute left-4 xl:left-1 top-10 md:top-15 bg-blue-200 rounded-full w-72 h-72 md:w-[300px] md:h-[300px] xl:w-[350px] xl:h-[350px] z-0"></div>
              <div className="absolute left-[50px] top-[90px] bg-thirdColor rounded-full w-56 h-56 md:w-[240px] md:h-[240px] xl:w-[250px] xl:h-[250px] z-0"></div> */}
            {/* <div className="relative z-10"> */}
            <img
              src={NinjaGirl}
              className="max-w-[300px] lg:max-w-[400px]"
              alt="Ninja Girl"
            />
            {/* </div> */}
            {/* </div> */}
          </Col>

          <Col
            span={24}
            md={{ span: 13 }}
            className="w-full flex flex-col items-start justify-end gap-3 z-10 py-10 md:py-0"
          >
            <span className="flex flex-row justify-center items-center gap-4 bg-thirdColor rounded-xl px-5 md:px-6 mb-3">
              <SearchAI className="w-[36px] md:w-[40px]" />{" "}
              <p className="text-white text-xl md:text-2xl font-normal">
                AI Powered
              </p>
            </span>

            <h1 className="title-level2 custom-font w-full lg:max-w-[560px]">
              Hey Ninja, I want to... <br />
              <div className="text-thirdColor">Paint my room</div>
            </h1>

            <div className="border-b-[2px] border-black w-60 mt-2"></div>

            <p className="text-lg md:text-xl font-normal mt-5 w-full lg:max-w-[500px]">
              Simply tell JobsNinja Ai what you need done, in any of the 50+
              languages and it will find you the most suitable workers for your
              task!
            </p>

            <span className="flex flex-row justify-center items-center gap-4 bg-primaryDarkest rounded-lg px-5 md:px-6 py-2 mt-3 mb-5 md:mb-10">
              <p className="text-white text-base md:text-xl font-normal">
                50+ Languages
              </p>
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HeyNinja;
