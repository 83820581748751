import React, { useContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import CreateJobPost from "../pages/CreateJobPost";
import Subscription from "../pages/Subscription";
import Login from "../pages/Login";
import { getLocalStoragedata } from "../helpers/encryptHelpers/storageHelper";
import { AuthContext } from "../context/AuthContext";
import MyProfile from "../pages/MyProfile";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import UserDeletion from "../pages/UserDeletion";
import Support from "../pages/Support";
import WorkerQRCode from "../pages/WorkerQRCode";
import ClientQRCode from "../pages/ClientQRCode";
import TermsConditions from "../pages/TermsConditions";
import SubscriptionDetails from "../pages/SubscriptionDetails";
import { SubscriptionContext } from "../context/SubscriptionContext";

const RouterSet = () => {
  let { token } = useContext(AuthContext);
  const tokenValue = getLocalStoragedata("token") || token;

  let { userLocation, fetchCountryCode } = useContext(SubscriptionContext);

  useEffect(() => {
    fetchCountryCode();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create-job-post" element={<CreateJobPost />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/user/deletion" element={<UserDeletion />} />
        <Route path="/support" element={<Support />} />
        <Route path="/worker" element={<WorkerQRCode />} />
        <Route path="/client" element={<ClientQRCode />} />
        <Route path="/terms-condition" element={<TermsConditions />} />
        <Route path="/subscription-details" element={<SubscriptionDetails />} />

        {(tokenValue !== null) &
        (tokenValue !== "") &
        (tokenValue !== undefined) ? (
          <>
            <Route
              path="/subscription"
              element={
                <PrivateRoute
                  element={<Subscription />}
                  role={["au", "nz", "sg"].includes(userLocation) ? 6 : -1}
                />
              }
            ></Route>

            <Route path="/my-profile" element={<MyProfile />} />
          </>
        ) : (
          <Route path="*" element={<Home />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default RouterSet;

const PrivateRoute = ({ element, role }) => {
  const userType = getLocalStoragedata("userTypeId");

  if (userType === role) {
    return element;
  } else {
    return <Navigate to="/" replace />;
  }
};
