import React from "react";
import OfferWorkers from "../../assets/img/workerOffer.png";

const WorkersOffers = () => {
  return (
    <div className="flex w-full justify-center items-center relative">
      <div className="max-w-[1073px] bg-[#EAE5DE] rounded-lg">
        <div className="relative">
          <img src={OfferWorkers} alt="offers-for-workers" />
          <div className="flex justify-center items-center">
            <div className="absolute bg-white max-w-[300px] md:max-w-[500px] lg:top-[400px]">
              <p className="text-xl md:text-3xl lg:text-4xl font-semibold text-center p-5 lg:p-8">
                Independent Contractors, Freelancers, And Part-Time Workers
                Offer Over 250 Services With Us.
              </p>
            </div>
          </div>
        </div>
        <div className="h-28 md:h-40 lg:h-56"></div>
      </div>
    </div>
  );
};

export default WorkersOffers;
