import React, { useRef, useState } from "react";
import { Col, ConfigProvider, Row, Select } from "antd";
import SearchIcon from "../../assets/svg/SearchIcon";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { NotificationContext } from "../../context/NotificationContext";
import { setLocalStorageData } from "../../helpers/encryptHelpers/storageHelper";
import CreateJobPostServices from "../../services/CreateJobPostServices";

const SearchWithSelector = () => {
  const {
    searchDropDownData,
    setSerchDropDownData,

    stepperCurrentSteps,
    setStepperCurrentSteps,

    isSearchDropdownOpen,
    setSearchDropdownIsOpen,

    setSelectedCategoryID,
    setSelectedCategoryDescription,

    // setStepsOpenHandler,
    setLocationValues,
    setTaskTimeValues,
    setjobValues,
    setdescriptionValues,

    completedStep,

    setSelectedCountryType,
    searchRef,

    searchValue,
    setSearchValue,
    setSelectedJobType,
  } = useContext(CreateJobPostContext);

  const navigate = useNavigate();

  const { openNotification, handleError } = useContext(NotificationContext);

  const { getRelatedSearchedJobTypes } = CreateJobPostServices();

  const [isSelecteCategory, setIsSelectCategory] = useState(false);

  const handleGetRelatedJobTypes = async (e) => {
    try {
      const response = await getRelatedSearchedJobTypes({
        search: e,
        job_category_id: 0,
      });
      if (response?.data?.success) {
        setTaskerListData(response?.data?.output);
      } else {
        openNotification("warning", response?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const setTaskerListData = (items) => {
    var taskerListObject = [];
    items?.forEach((item) => {
      let data = {
        value: item.job_type_id,
        label: (
          <p className="flex flex-row gap-1 justify-start items-center">
            {item.job_type}
            {item?.similar_job_type !== "-" && (
              <p className="text-base text-textColorZero">
                ({item?.similar_job_type})
              </p>
            )}
          </p>
        ),
      };
      taskerListObject.push(data);
    });
    setSerchDropDownData(taskerListObject);
  };

  return (
    <div className="flex w-full justify-center items-center overflow-hidden">
      <div className="max-w-[1073px] mx-2">
        <div className="flex flex-col items-center w-full p-4">
          <div className="flex flex-row gap-2 items-center border-primaryColor border-[2px] rounded-full p-2 px-5 h-[50px] max-w-[300px] sm:h-[60px] sm:max-w-[600px] sm:max-[600px] md:w-[730px] md:max-w-[730px] md:h-[82px]">
            <Row className="flex flex-row w-full">
              <Col span={22} className="flex justify-center items-center">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#ffffff",
                      colorInfo: "#ffffff",
                    },
                    components: {
                      Select: {
                        lineWidth: 0,
                        boxShadow: "none",
                        boxShadow: "#ffffff",
                        fontSize: 20,
                      },
                    },
                  }}
                >
                  <Select
                    showSearch
                    allowClear={false}
                    ref={searchRef}
                    className="w-full custom-clear-icon border-none focus-within:shadow-none focus:shadow-none sm:!text-base md:!text-xl font-medium"
                    placeholder="What home service are you looking for?"
                    suffixIcon={false}
                    style={{
                      width: "100%",
                    }}
                    filterOption={false}
                    open={
                      isSearchDropdownOpen === false || searchValue === ""
                        ? false
                        : true
                    }
                    onBlur={() => {
                      setSearchDropdownIsOpen(false);
                      setSerchDropDownData([]);
                      setSelectedCategoryDescription("");
                    }}
                    options={searchDropDownData}
                    searchValue={searchValue}
                    onSearch={(e) => {
                      setSearchValue(e);
                      handleGetRelatedJobTypes(e);
                      setSelectedCategoryID(null);
                      setSelectedCategoryDescription("");
                      setSearchDropdownIsOpen(true);
                    }}
                    onSelect={(e) => {
                      setIsSelectCategory(true);
                      setSelectedCategoryID(null);
                      setSelectedCategoryDescription("");
                      setSearchDropdownIsOpen(false);
                      setStepperCurrentSteps(0);
                      setLocationValues({
                        country: undefined,
                        conuntryName: undefined,
                        state: undefined,
                        suburb: undefined,
                        postalCodetype1: undefined,
                        postalCodetype2: undefined,
                        district: undefined,
                        districtName: undefined,
                        city: undefined,
                        cityName: undefined,
                      });

                      setjobValues({
                        jobOne: true,
                        jobTwo: false,
                        jobThree: false,
                      });
                      setdescriptionValues("");
                      setSelectedCountryType(1);

                      setLocalStorageData("searchSelectedJobTypeId", e);
                      setSelectedJobType(
                        searchDropDownData?.find((item) => item?.value === e)
                          ?.label
                      );
                      setLocalStorageData(
                        "searchSelectedJobTypeName",
                        searchDropDownData?.find((item) => item?.value === e)
                          ?.label
                      );

                      // if (isSelecteCategory) {
                      navigate("./create-job-post");
                      // }
                    }}
                  />
                </ConfigProvider>
              </Col>

              <Col
                span={2}
                className="flex justify-end items-end"
                onClick={() => {}}
              >
                <div className="cursor-pointer">
                  <SearchIcon className="w-[30px] sm:w-[40px] sm:h-[40px] md:w-[51px] md:h-[51px]" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex justify-center items-center mt-8 md:mt-10">
          <h1 className="title-level1 text-center custom-font max-w-[700px]">
            Find Reliable Workers For Home Services,{" "}
            <span className="text-primaryColor">Fast.</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SearchWithSelector;
