import React from "react";

const DownArrowIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="19.5"
        height="19.5"
        rx="9.75"
        transform="matrix(1 0 0 -1 1 21)"
        stroke="#2C8B52"
        strokeWidth="1.125"
      />
      <path
        d="M13.6 9.825L10.675 12.75L7.75 9.825"
        stroke="#2C8B52"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownArrowIcon;
