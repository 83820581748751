import React, { useState } from "react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import NavbarProfile from "../navbarProfile/NavbarProfile";
import NavBarFaceLogo from "../../assets/svg/NavBarFaceLogo";
import { Dropdown, Menu } from "antd";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import CommonHeaderMenu from "./CommonHeaderMenu";

const MobileMenu = ({ menuItems0, menuItems1, menuItems2 }) => {
  const location = useLocation();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const signInItems = [
    {
      key: "1",
      label: (
        <Link to="../login" state={{ role: 1, type: "asNew" }}>
          <div className="flex flex-row justify-between items-center border-b border-b-white pb-1 text-base font-medium">
            <span className="text-white">As a Worker</span>
            <MdKeyboardArrowRight className="text-white text-lg mt-1" />
          </div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="../login" state={{ role: 2, type: "asNew" }}>
          <div className="flex flex-row justify-between items-center pb-1 text-base font-medium">
            <span className="text-white">As a Client</span>
            <MdKeyboardArrowRight className="text-white text-lg mt-1" />
          </div>
        </Link>
      ),
    },
  ];

  const menu = (
    <Menu
      items={signInItems}
      style={{
        width: 135,
        background: "#2c8b52",
        color: "white",
        borderRadius: "8px",
      }}
    />
  );

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full px-2 my-2">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2 items-center justify-center">
            <button className="text-3xl p-2right-0">
              <IoMdMenu
                className="text-primaryColor"
                onClick={() => {
                  toggleMobileMenu();
                }}
              />
            </button>
          </div>
          <Link to="/">
            <NavBarFaceLogo />
          </Link>
        </div>

        {getLocalStoragedata("token") ? (
          <NavbarProfile />
        ) : (
          <>
            <Dropdown overlay={menu} trigger={["click"]}>
              <button className="bg-primaryColor text-base font-medium text-white py-2 px-5 rounded-lg w-[135px]">
                <p className="flex flex-row justify-between items-center">
                  Sign in
                  <FaAngleDown />
                </p>
              </button>
            </Dropdown>
          </>
        )}
      </div>

      <div
        className={`fixed top-0 right-0 w-full h-96 bg-primaryLightest text-black transform z-[99999] ${
          isMobileMenuOpen ? "translate-y-0" : "-translate-y-full"
        } transition-transform duration-300 ease-in-out overflow-hidden`}
      >
        <div className="flex justify-end">
          <button className="text-3xl p-3 mr-2">
            <IoMdClose
              className="text-primaryColor"
              onClick={() => {
                closeMobileMenu();
              }}
            />
          </button>
        </div>

        <div className="flex flex-col items-center h-full">
          {getLocalStoragedata("token") ? (
            <>
              {getLocalStoragedata("userTypeId") === 6 ? (
                <>
                  {/* worker header */}
                  <CommonHeaderMenu
                    menuItems={menuItems1}
                    locationPathname={location.pathname}
                  />
                </>
              ) : (
                <>
                  {/* client header */}
                  <CommonHeaderMenu
                    menuItems={menuItems2}
                    locationPathname={location.pathname}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {/* user who is not log */}
              <CommonHeaderMenu
                menuItems={menuItems0}
                locationPathname={location.pathname}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
