import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import NavbarLogo from "../../assets/svg/NavbarLogo";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import NavbarProfile from "../navbarProfile/NavbarProfile";
import { Dropdown, Menu } from "antd";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import CommonHeaderMenu from "./CommonHeaderMenu";
import { SubscriptionContext } from "../../context/SubscriptionContext";

const NavBar = () => {
  const location = useLocation();
  let { userLocation, fetchCountryCode } = useContext(SubscriptionContext);

  useEffect(() => {
    fetchCountryCode();
  }, []);

  const menuItems0 = [
    { name: "Home", url: "/" },
    { name: "Subscription", url: "/subscription-details" },
  ]; // not login user

  const menuItems1 = [
    { name: "Home", url: "/" },
    {
      name: "Subscription",
      url: ["au", "nz", "sg"].includes(userLocation)
        ? "/subscription"
        : "/subscription-details",
    },
  ]; //worker

  const menuItems2 = [{ name: "Home", url: "/" }]; //client

  const signInItems = [
    {
      key: "1",
      label: (
        <Link to="../login" state={{ role: 1, type: "asNew" }}>
          <div className="flex flex-row justify-between items-center border-b border-b-white pb-1 text-base font-medium">
            <span className="text-white">As a Worker</span>
            <MdKeyboardArrowRight className="text-white text-lg mt-1" />
          </div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="../login" state={{ role: 2, type: "asNew" }}>
          <div className="flex flex-row justify-between items-center pb-1 text-base font-medium">
            <span className="text-white">As a Client</span>
            <MdKeyboardArrowRight className="text-white text-lg mt-1" />
          </div>
        </Link>
      ),
    },
  ];

  const menu = (
    <Menu
      items={signInItems}
      style={{
        width: 135,
        background: "#2c8b52",
        color: "white",
        borderRadius: "8px",
      }}
    />
  );

  return (
    <div className="flex flex-row gap-2 w-full items-center justify-center border-b-[2px] navbar">
      <div className="max-w-[1280px] w-full my-2 mr-1 xl:mr-0">
        {/* Desktop view */}
        <div className="hidden md:flex flex-row justify-between items-center">
          <Link to="/">
            <NavbarLogo />
          </Link>

          <div className="flex flex-row gap-4 justify-center items-center">
            {getLocalStoragedata("token") ? (
              <>
                {getLocalStoragedata("userTypeId") === 6 ? (
                  <>
                    {/* worker header */}
                    <CommonHeaderMenu
                      menuItems={menuItems1}
                      locationPathname={location.pathname}
                    />

                    <NavbarProfile />
                  </>
                ) : (
                  <>
                    {/* client header */}
                    <CommonHeaderMenu
                      menuItems={menuItems2}
                      locationPathname={location.pathname}
                    />
                    <NavbarProfile />
                  </>
                )}
              </>
            ) : (
              <>
                {/* user who is not log */}
                <CommonHeaderMenu
                  menuItems={menuItems0}
                  locationPathname={location.pathname}
                />

                {/* login types menu */}
                <Dropdown overlay={menu} trigger={["click"]}>
                  <button className="bg-primaryColor text-base font-medium text-white py-2 px-5 rounded-lg w-[135px]">
                    <p className="flex flex-row justify-between items-center">
                      Sign in
                      <FaAngleDown />
                    </p>
                  </button>
                </Dropdown>
              </>
            )}
          </div>
        </div>

        {/* Mobile view */}
        <div className="md:hidden flex">
          <MobileMenu
            menuItems0={menuItems0}
            menuItems1={menuItems1}
            menuItems2={menuItems2}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
