import React from "react";
import { Col, Row } from "antd";
import WorkerImage from "../../assets/img/forWorkers.png";

const ForWorkers = () => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="max-w-[1073px] xl:w-[1073px] mx-2 bg-[#FFD785] rounded-lg overflow-hidden">
        <Row className="w-full px-2 md:px-10">
          <Col
            span={24}
            md={{ span: 10 }}
            className="flex items-center justify-center md:items-end md:justify-start w-full"
          >
            <div className="max-w-[300px] md:max-w-[350px]">
              <img src={WorkerImage} alt="client" />
            </div>
          </Col>

          <Col
            span={24}
            md={{ span: 14 }}
            className="w-full flex flex-col items-start justify-center gap-3 md:py-0"
          >
            <div className="bg-primaryDark rounded-lg mb-2 mt-10">
              <p className="text-xl md:text-[24px] font-normal text-white px-2">
                For Workers
              </p>
            </div>

            <h1 className="title-level2 custom-font">
              offering the best
              <br /> value, and Helping
              <br /> you Grow
            </h1>

            <div className="border-b-[2px] border-black w-60 mt-2"></div>

            <p className="text-lg md:text-xl font-normal mt-5 w-full mb-10">
              The platform brings the most value, empowering small businesses to
              grow and build their own empires. Workers can define their skills,
              set their service areas, and choose their own prices, offering
              flexible earning opportunities.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForWorkers;
