import React from "react";

const YellowLogo = () => {
  return (
    <svg
      width="57"
      height="58"
      viewBox="0 0 57 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_1211_1717)">
        <rect
          x="3.75"
          y="0.831055"
          width="50"
          height="50"
          rx="11"
          fill="#E3E3E3"
          shape-rendering="crispEdges"
        />
        <rect
          x="6.05566"
          y="0.831055"
          width="45.3891"
          height="50"
          rx="11"
          fill="#E3E3E3"
        />
        <path
          d="M29.767 36.7127C29.2455 36.7996 28.811 36.452 28.8979 36.0174C28.8979 35.0614 28.8979 34.2793 28.8979 33.9316C28.8979 33.7578 28.7241 33.6709 28.6372 33.6709C26.7252 33.6709 24.9001 33.4971 22.3798 33.2364C18.8166 32.8018 15.4272 29.4993 14.645 24.3718C14.2105 21.4169 13.9497 17.4191 14.645 13.3345C15.1664 10.2058 17.3391 7.51165 22.3798 6.90329C27.4204 6.29494 29.767 6.38184 35.1552 6.9902C40.2828 7.51165 42.7162 9.94507 43.1508 13.3345C43.5853 16.637 43.4984 20.8955 43.1508 24.3718C42.3686 30.9768 35.6767 35.6698 29.767 36.7127Z"
          fill="#074924"
        />
        <path
          d="M39.6741 15.4203L38.1966 16.4632C34.981 18.549 31.8523 19.2442 28.8106 19.418C25.7688 19.2442 22.5532 18.549 19.4245 16.4632L17.9471 15.4203C16.6435 14.5512 15.6875 15.7679 16.2089 17.5061L17.3387 21.7646C17.5126 22.9813 18.3816 23.8503 19.2507 24.198C19.6852 24.3718 20.2936 24.4587 20.902 24.4587C23.3354 24.4587 25.5081 22.6336 28.2891 22.6336C30.9833 22.6336 34.025 24.4587 36.4585 24.4587C37.0668 24.4587 37.6752 24.3718 38.1097 24.198C38.9788 23.9372 39.8479 22.9813 40.1955 21.7646L41.3253 17.5061C41.9337 15.8548 40.8908 14.5512 39.6741 15.4203Z"
          fill="#FFCA7A"
        />
        <path
          d="M19.4253 20.4611C19.4253 20.4611 20.5551 20.4611 21.7718 20.8087C22.7278 21.0695 24.0314 21.9385 24.0314 21.9385C24.2052 22.1124 24.5528 22.0255 24.6397 21.7647C24.7266 21.504 24.7266 21.2433 24.4659 21.0695C24.379 21.0695 22.9885 20.1135 21.9456 19.8528C20.642 19.5051 19.5122 19.5051 19.4253 19.5051C19.1645 19.5051 18.9907 19.7659 18.9907 20.0266C18.9907 20.2873 19.1645 20.4611 19.4253 20.4611Z"
          fill="#074924"
        />
        <path
          d="M37.4149 20.4611C37.4149 20.4611 36.2851 20.4611 35.0684 20.8087C34.1124 21.0695 32.8088 21.9385 32.8088 21.9385C32.635 22.1124 32.2873 22.0255 32.2004 21.7647C32.1135 21.504 32.1135 21.2433 32.3742 21.0695C32.4611 21.0695 33.8517 20.1135 34.8946 19.8528C36.1982 19.5051 37.4149 19.5051 37.5018 19.5051C37.7625 19.5051 37.9363 19.7659 37.9363 20.0266C37.8494 20.2873 37.6756 20.4611 37.4149 20.4611Z"
          fill="#074924"
        />
        <path
          d="M25.0147 37.8452L23.1042 45.1738H20.9432L19.774 40.3507L18.563 45.1738H16.402L14.5438 37.8452H16.4542L17.5086 43.1799L18.8136 37.8452H20.7762L22.029 43.1799L23.0938 37.8452H25.0147ZM28.5616 45.2573C27.9909 45.2573 27.4759 45.1355 27.0166 44.892C26.5642 44.6484 26.2057 44.3004 25.9413 43.848C25.6838 43.3956 25.555 42.8667 25.555 42.2612C25.555 41.6626 25.6872 41.1372 25.9517 40.6848C26.2162 40.2255 26.5781 39.874 27.0374 39.6304C27.4968 39.3868 28.0118 39.265 28.5825 39.265C29.1532 39.265 29.6682 39.3868 30.1276 39.6304C30.5869 39.874 30.9488 40.2255 31.2133 40.6848C31.4777 41.1372 31.61 41.6626 31.61 42.2612C31.61 42.8597 31.4743 43.3887 31.2028 43.848C30.9384 44.3004 30.573 44.6484 30.1067 44.892C29.6473 45.1355 29.1323 45.2573 28.5616 45.2573ZM28.5616 43.7123C28.9026 43.7123 29.1915 43.587 29.4281 43.3365C29.6717 43.0859 29.7935 42.7275 29.7935 42.2612C29.7935 41.7949 29.6752 41.4365 29.4385 41.1859C29.2089 40.9353 28.9235 40.8101 28.5825 40.8101C28.2345 40.8101 27.9457 40.9353 27.716 41.1859C27.4863 41.4295 27.3715 41.7879 27.3715 42.2612C27.3715 42.7275 27.4829 43.0859 27.7056 43.3365C27.9352 43.587 28.2206 43.7123 28.5616 43.7123ZM34.3422 40.3194C34.551 39.9993 34.812 39.7487 35.1252 39.5678C35.4384 39.3798 35.7864 39.2859 36.1692 39.2859V41.1755H35.6785C35.2331 41.1755 34.899 41.2729 34.6763 41.4678C34.4536 41.6557 34.3422 41.9897 34.3422 42.47V45.1738H32.557V39.3485H34.3422V40.3194ZM40.5823 45.1738L38.8076 42.731V45.1738H37.0224V37.4485H38.8076V41.7183L40.5719 39.3485H42.7746L40.3527 42.2716L42.7955 45.1738H40.5823Z"
          fill="#074924"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1211_1717"
          x="0.75"
          y="0.831055"
          width="56"
          height="57"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1211_1717"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0470588 0 0 0 0 0.0470588 0 0 0 0 0.0509804 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1211_1717"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1211_1717"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0470588 0 0 0 0 0.0470588 0 0 0 0 0.0509804 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1211_1717"
            result="effect2_dropShadow_1211_1717"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1211_1717"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default YellowLogo;
