import React, { useContext, useEffect, useState } from "react";
import NavbarLogo from "../../assets/svg/NavbarLogo";
import { Button, Col, Form, Row } from "antd";
import { NotificationContext } from "../../context/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { shaEncryption } from "../../helpers/encryptHelpers/encryption";
import { useNavigate } from "react-router-dom";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../../helpers/encryptHelpers/storageHelper";
import LoginServices from "../../services/LoginServices";
import HeaderConfig from "../../helpers/HeaderConfig";
import { InputOTP } from "antd-input-otp";
import PassCode from "../../assets/svg/PassCode";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import BackIcon from "../../assets/svg/BackIcon";
import { SubscriptionContext } from "../../context/SubscriptionContext";
import DetailsBox from "../../components/alertBox/DetailsBox";
import PassCode1 from "../../assets/img/passcode1.png";
import PassCode2 from "../../assets/img/passcode2.png";
import PassCode3 from "../../assets/img/passcode3.png";
import PassCode4 from "../../assets/img/passcode4.png";

const PinValidateCard = ({ role, type }) => {
  let {
    tryToLoginUserID,
    setToken,
    setCurrentUserData,
    setTryToLoginUserID,
    setAuthSteps,
  } = useContext(AuthContext);
  const {
    setStepperCurrentSteps,
    setCompletedStep,
    locationValues,
    setTaskerList,
    selectedCountryType,
  } = useContext(CreateJobPostContext);
  let { openNotification, handleError } = useContext(NotificationContext);

  const { baseUrl } = HeaderConfig();
  const { pinValidate, getAllAuthUserData } = LoginServices();
  const { getAllTakerList } = CreateJobPostServices();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isPinComplete, setIsPinComplete] = useState(false);

  const [openCloseNotificationAlertBox, setOpenCloseNotificationAlertBox] =
    useState(false);

  let { userLocation, fetchCountryCode } = useContext(SubscriptionContext);

  useEffect(() => {
    fetchCountryCode();
  }, []);

  const handleVerifyPin = async (e) => {
    if (isPinComplete === false && e?.pin !== undefined) {
      setLoading(true);
      const config = {
        headers: {
          "Access-Control-Allow": true,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "App-Type": role,
        },
      };

      try {
        await pinValidate(
          {
            user_id: tryToLoginUserID,
            pin: shaEncryption(e?.pin?.join("")),
            is_web: 1,
          },
          config
        )
          .then((response) => {
            if (response?.data?.success) {
              if (response?.data?.output?.token) {
                getAuthUserData(response?.data?.output?.token);
              }
            } else {
              openNotification("warning", response?.data?.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });
      } catch (error) {
        openNotification("error", error);
      }
      setLoading(false);
    } else {
      setIsPinComplete(true);
    }
  };

  const getAuthUserData = async (token) => {
    let config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Origin: baseUrl,
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await getAllAuthUserData(config)
        .then((response) => {
          if (response?.data?.success) {
            setToken(token);
            setLocalStorageData("token", token);
            setLocalStorageData("userId", response?.data?.output?.user_id);
            setLocalStorageData(
              "userTypeId",
              response?.data?.output?.user_type_id
            );
            setLocalStorageData(
              "profilePic",
              response?.data?.output?.profile_picture
            );
            setLocalStorageData("userName", response?.data?.output?.first_name);
            setCurrentUserData(response?.data?.output);

            if (type === "asPayment") {
              if (["au", "nz", "sg"].includes(userLocation)) {
                navigate("../subscription");
              } else {
                navigate("../subscription-details");
              }
            } else if (type === "asNew") {
              navigate("../");
            } else if (type === "asClient") {
              fetchAllTaskerListData();
              setCompletedStep(1);
              setStepperCurrentSteps(1);
              navigate("../create-job-post");
            }
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchAllTaskerListData = async () => {
    try {
      await getAllTakerList({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        country_id: locationValues?.country,
        state_id:
          selectedCountryType === 1
            ? locationValues?.state
            : locationValues?.district,
        suburb_id:
          selectedCountryType === 1
            ? locationValues?.suburb
            : locationValues?.city,
        language_ids: [],
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList(response?.data.output);
          } else {
            setTaskerList([]);
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <>
      <div className="flex w-full justify-center items-center mt-28">
        <div className="max-w-[1073px] xl:w-[1073px] mx-2 relative">
          <div
            className="absolute top-12 left-2 xl:left-[350px] cursor-pointer"
            onClick={() => {
              setTryToLoginUserID();
              setAuthSteps(1);
            }}
          >
            <BackIcon />
          </div>
          <div className="flex justify-center items-center">
            <div className="shadow-lg px-4 md:px-5 pt-10 pb-16 rounded-lg flex flex-col justify-center items-center sm:w-[440px]">
              <NavbarLogo />

              <p className="text-sm md:text-lg font-bold my-5">
                Enter your passcode
              </p>

              <Form className="mt-5 w-full" onFinish={handleVerifyPin}>
                <span className="flex flex-row justify-center items-center gap-2 mb-5">
                  <PassCode />
                  <p className="text-sm font-medium">
                    Enter 4 digits passcode *
                  </p>
                </span>

                <Form.Item name="pin">
                  <InputOTP
                    type="password"
                    autoFocus
                    length={4}
                    inputType="numeric"
                    onChange={(otpValues) => {
                      if (otpValues.join("").length === 4) {
                        setIsPinComplete(false);
                      } else {
                        setIsPinComplete(true);
                      }
                    }}
                  />
                </Form.Item>

                {isPinComplete && (
                  <p className="text-sm font-normal text-center text-warringColorOne">
                    Invalid passCode!
                  </p>
                )}

                <p
                  className="text-xs font-bold text-primaryDark text-end my-5 cursor-pointer"
                  onClick={() => {
                    setOpenCloseNotificationAlertBox(true);
                  }}
                >
                  How to find your passcode?
                </p>

                <Form.Item>
                  <Button
                    type="primary"
                    className="w-full bg-primaryDarkest text-sm font-semibold mt-3"
                    loading={loading}
                    htmlType="submit"
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {openCloseNotificationAlertBox && (
        <DetailsBox
          open={openCloseNotificationAlertBox}
          onCancel={() => {
            setOpenCloseNotificationAlertBox(false);
          }}
          title="Setup a Passcode to use in login"
          content={
            <div className="flex flex-col justify-center items-center">
              <Row gutter={16} className="w-full">
                <Col
                  span={12}
                  sm={{ span: 6 }}
                  className="flex flex-col justify-start items-center mb-5"
                >
                  <img src={PassCode1} className="w-[100px] md:w-[120px]" />
                  <div className="flex flex-row gap-1">
                    <span>1.</span>
                    <p className="text-sm">
                      Go to{" "}
                      <span className="text-primaryColor font-semibold">
                        “Quick Settings”
                      </span>{" "}
                      JobsNinja mobile app
                    </p>
                  </div>
                </Col>
                <Col
                  span={12}
                  sm={{ span: 6 }}
                  className="flex flex-col justify-start items-center mb-5"
                >
                  <img src={PassCode2} className="w-[100px] md:w-[120px]" />
                  <div className="flex flex-row gap-1">
                    <span>2.</span>
                    <p className="text-sm">
                      Select{" "}
                      <span className="text-primaryColor font-semibold">
                        “PIN Setup”
                      </span>
                    </p>
                  </div>
                </Col>
                <Col
                  span={12}
                  sm={{ span: 6 }}
                  className="flex flex-col justify-start items-center mb-5"
                >
                  <img src={PassCode3} className="w-[100px] md:w-[120px]" />
                  <div className="flex flex-row gap-1">
                    <span>3.</span>
                    <p className="text-sm">
                      Enter your preferred{" "}
                      <span className="text-primaryColor font-semibold">
                        “4 Digit Passcode”
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-row gap-1">
                    <span>4.</span>
                    <p className="text-sm">
                      Enter the same passcode again for confirmation
                    </p>
                  </div>
                </Col>
                <Col
                  span={12}
                  sm={{ span: 6 }}
                  className="flex flex-col justify-start items-center mb-5"
                >
                  <img src={PassCode4} className="w-[100px] md:w-[120px]" />
                  <p className="text-sm">
                    <span className="text-primaryColor font-semibold">
                      Hooray!
                    </span>
                    Now you have successfully setup your passcode.
                  </p>
                  <p className="text-sm">
                    You can use this Passcode for your future logins
                  </p>
                </Col>
              </Row>
            </div>
          }
        />
      )}
    </>
  );
};

export default PinValidateCard;
