import React, { useContext, useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import JobDetails from "../sections/createJobPost/JobDetails";
import { CreateJobPostContext } from "../context/CreateJobPostContext";
import SelectedTaskers from "../sections/createJobPost/SelectedTaskers";

const CreateJobPost = () => {
  const { stepperCurrentSteps } = useContext(CreateJobPostContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-primaryLightest">
      <NavBar />
      <div className="content">
        {stepperCurrentSteps === 0 ? <JobDetails /> : <SelectedTaskers />}
      </div>
    </div>
  );
};

export default CreateJobPost;
