import React, { useContext } from "react";
import { Divider, Popover, Typography } from "antd";
import UserColorProfile from "../userColorProfile/UserColorProfile";
import { Link, useNavigate } from "react-router-dom";
import NavBarUserIcon from "../../assets/svg/NavBarUserIcon";
import NavbarLogout from "../../assets/svg/NavbarLogout";
import { MdArrowForwardIos } from "react-icons/md";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import HeaderConfig from "../../helpers/HeaderConfig";
import LoginServices from "../../services/LoginServices";
const { Text } = Typography;

const NavbarProfile = ({ open }) => {
  let { openNotification, handleError } = useContext(NotificationContext);
  const { configAuth } = HeaderConfig();
  const { userLogOut } = LoginServices();

  const navigate = useNavigate();

  const logOut = () => {
    userLogOut({ token: getLocalStoragedata("token") }, configAuth)
      .then((response) => {
        if (response.data.success) {
          navigate("/");
          openNotification("success", response.data.message);
          localStorage.removeItem("userId");
          localStorage.removeItem("token");
          localStorage.removeItem("profilePic");
          localStorage.removeItem("userName");
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div className="flex flex-row gap-4">
      <Popover
        content={
          <div className="w-[200px] h-fit flex flex-col items-center justify-start">
            <Link className="w-full" to="/my-profile">
              <div className="w-full h-7 flex justify-center items-start">
                <div className="w-[20%] h-full flex-row-center">
                  {getLocalStoragedata("profilePic") === null ? (
                    <UserColorProfile
                      name={
                        decryptSecureData(getLocalStoragedata("userName")) ||
                        "N Z"
                      }
                      color={"#3bb96e"}
                      size="30px"
                      textSize="12px"
                    />
                  ) : (
                    <img
                      src={getLocalStoragedata("profilePic")}
                      alt=""
                      className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                    />
                  )}
                </div>
                <div className="w-[80%] h-full flex flex-col items-start justify-center">
                  <Text className="text-sm font-semibold">
                    {decryptSecureData(getLocalStoragedata("userName"))}
                  </Text>
                </div>
              </div>
            </Link>
            <Divider className="my-4 bg-textColorZero h-[2px]" />

            <Link className="w-full" to="/my-profile">
              <div className="w-full h-7 flex justify-center items-start">
                <div className="w-[20%] h-full flex-row-center">
                  <NavBarUserIcon />
                </div>
                <div className="w-[80%] h-full flex flex-row items-center justify-between">
                  <Text className="text-sm font-semibold">
                    My Public Profile
                  </Text>
                  <MdArrowForwardIos />
                </div>
              </div>
            </Link>

            <div
              className="w-full h-7 flex justify-center items-start cursor-pointer mt-4"
              onClick={() => {
                logOut();
              }}
            >
              <div className="w-[20%] h-full flex-row-center">
                <NavbarLogout />
              </div>
              <div className="w-[80%] h-full flex flex-row items-center justify-between">
                <Text className="text-sm font-semibold">Log out</Text>
              </div>
            </div>
          </div>
        }
        placement="bottomRight"
        arrow={false}
        trigger="click"
        open={open}
      >
        <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
          {getLocalStoragedata("profilePic") === null ? (
            <UserColorProfile
              name={decryptSecureData(getLocalStoragedata("userName")) || "N Z"}
              color={"#3bb96e"}
              size="40px"
              textSize="16px"
            />
          ) : (
            <img
              src={getLocalStoragedata("profilePic")}
              alt=""
              className="flex w-[32px] h-[32px] rounded-full border-[2px]"
            />
          )}
        </div>
      </Popover>
    </div>
  );
};

export default NavbarProfile;
