import React, { useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import JobninjaClient from "../assets/img/jobninjaClient.png";
import PlayStore2 from "../assets/img/playStore2.png";
import AppStore2 from "../assets/img/appStore2.png";
import GreenPhone from "../assets/img/greenPhone.png";
import { QRCode } from "antd";

const ClientQRCode = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBar />
      <div className="relative content overflow-hidden">
        <div className="absolute -right-56 -top-40 bg-primaryLightest rounded-full w-[400px] h-[400px] md:w-[600px] md:h-[600px] lg:w-[700px] lg:h-[700px] z-0"></div>
        <div className="absolute -right-44 -top-24 bg-[#a7e7c1] rounded-full w-[280px] h-[280px] md:w-[400px] md:h-[400px] lg:w-[450px] lg:h-[450px] z-0"></div>

        <div className="mt-10 flex flex-col gap-8 md:gap-10 lg:gap-16">
          <div className="flex w-full justify-center items-center z-10">
            <div className="max-w-[1073px] xl:w-[1073px]">
              <div className="flex flex-col md:flex-row mx-2">
                <div className="md:w-[70%]">
                  <img
                    src={JobninjaClient}
                    alt="client"
                    className="max-w-[200px] sm:max-w-[250px] md:max-w-[300px]"
                  />
                  <h1 className="title-level1 custom-font pt-6 md:pt-12">
                    Download The Fastest Home Services App
                  </h1>
                  <p className="text-lg md:text-xl font-normal mt-3 md:pt-5">
                    JobsNinja delivers the fastest home services experience by
                    allowing you to connect directly with taskers, fast search
                    with AI voice search and by finding nearby taskers—no
                    waiting required.
                  </p>

                  <div className="flex flex-row gap-10 lg:gap-16 pt-8 md:pt-16 justify-center md:justify-start mb-10 md:mb-0">
                    <div>
                      <img
                        src={AppStore2}
                        className="w-[100px] md:w-[120px] pb-3"
                      />

                      <QRCode
                        value={
                          "https://apps.apple.com/app/jobsninja/id6451492483" ||
                          "-"
                        }
                        className="max-w-[100px] max-h-[100px] md:max-w-[140px] md:max-h-[140px]"
                      />

                      <button
                        className="bg-thirdColor text-sm text-white font-medium p-2 md:px-5 md:py-2 rounded-full mt-5"
                        onClick={() => {
                          window.open(
                            "https://apps.apple.com/app/jobsninja/id6451492483",
                            "_blank"
                          );
                        }}
                      >
                        Download Now
                      </button>
                    </div>

                    <div>
                      <img
                        src={PlayStore2}
                        className="w-[100px] md:w-[120px] pb-3"
                      />

                      <QRCode
                        value={
                          "https://play.google.com/store/apps/details?id=au.jobninja.customer&hl=en" ||
                          "-"
                        }
                        className="max-w-[100px] max-h-[100px] md:max-w-[140px] md:max-h-[140px]"
                      />

                      <button
                        className="bg-thirdColor text-sm text-white font-medium p-2 md:px-5 md:py-2 rounded-full mt-[22px]"
                        onClick={() => {
                          window.open(
                            "https://play.google.com/store/apps/details?id=au.jobninja.customer&hl=en",
                            "_blank"
                          );
                        }}
                      >
                        Download Now
                      </button>
                    </div>
                  </div>
                </div>

                <div className="md:w-[30%] flex justify-center md:justify-end items-center">
                  <img src={GreenPhone} className="w-[200px] h-[436px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ClientQRCode;
