import React from "react";
import { Link } from "react-router-dom";
import XLogo from "../../assets/svg/XLogo";
import InstaLogo from "../../assets/svg/InstaLogo";
import PinLogo from "../../assets/svg/PinLogo";
import LinkLogo from "../../assets/svg/LinkLogo";
import TickLogo from "../../assets/svg/TickLogo";
import YoutubeLogo from "../../assets/svg/YoutubeLogo";
import FooterLogo from "../../assets/svg/FooterLogo";
import PlayStore from "../../assets/img/playStore.png";
import AppleStore from "../../assets/img/appleStore.png";
import JobninjaFooterClient from "../../assets/svg/JobninjaFooterClient";
import JobNinjaFooterWork from "../../assets/svg/JobNinjaFooterWork";

const Footer = () => {
  return (
    <div className="flex flex-row gap-2 w-full min-h-[319px] items-center justify-center bg-darkestColor px-5 mt-8 md:mt-16">
      <div className="max-w-[1280px] w-full my-20 md:my-0">
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <div className="flex flex-col justify-center items-center  md:justify-start md:items-start w-[40%]">
            <FooterLogo />
            <p className="text-sm font-normal text-textColorZero pt-2">
              The ultimate on-demand job platform
            </p>

            <div className="flex flex-row gap-1 pt-10 md:pt-24">
              <span
                className="cursor-pointer"
                onClick={() => {
                  const linkUrl = "https://x.com/jobsNinjaApp";
                  window.open(linkUrl, "_blank");
                }}
              >
                <XLogo />
              </span>

              <span
                className="cursor-pointer"
                onClick={() => {
                  const linkUrl = "https://www.instagram.com/jobsninjaau/";
                  window.open(linkUrl, "_blank");
                }}
              >
                <InstaLogo />
              </span>

              <span
                className="cursor-pointer"
                onClick={() => {
                  const linkUrl = "https://au.pinterest.com/JobsNinja/";
                  window.open(linkUrl, "_blank");
                }}
              >
                <PinLogo />
              </span>

              <span
                className="cursor-pointer"
                onClick={() => {
                  const linkUrl =
                    "https://www.linkedin.com/company/jobsninjaapp";
                  window.open(linkUrl, "_blank");
                }}
              >
                <LinkLogo />
              </span>

              <span
                className="cursor-pointer"
                onClick={() => {
                  const linkUrl = "https://www.tiktok.com/@jobsninjaau";
                  window.open(linkUrl, "_blank");
                }}
              >
                <TickLogo />
              </span>

              <span
                className="cursor-pointer"
                onClick={() => {
                  const linkUrl = "https://www.youtube.com/@jobsNinjaApp";
                  window.open(linkUrl, "_blank");
                }}
              >
                <YoutubeLogo />
              </span>
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:gap-8 mt-8 md:mt-0 w-[60%]">
            <div className="flex flex-col gap-3 justify-center items-center md:justify-start md:items-start">
              <p className="text-sm font-bold text-white">Download our apps</p>

              <div className="flex flex-row md:flex-col lg:flex-row gap-5">
                <div className="flex justify-center items-center md:justify-start md:items-start flex-col gap-2">
                  <JobninjaFooterClient />
                  <button
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=au.jobninja.customer&hl=en",
                        "_blank"
                      );
                    }}
                  >
                    <img src={PlayStore} className="w-[80px] xl:w-[100px]" />
                  </button>
                  <button
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/app/jobsninja/id6451492483",
                        "_blank"
                      );
                    }}
                  >
                    <img src={AppleStore} className="w-[80px] xl:w-[100px]" />
                  </button>
                </div>
                <div className="flex justify-center items-center md:justify-start md:items-start flex-col gap-2">
                  <JobNinjaFooterWork />
                  <button
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=au.jobninja.trady&hl=en",
                        "_blank"
                      );
                    }}
                  >
                    <img src={PlayStore} className="w-[80px] xl:w-[100px]" />
                  </button>
                  <button
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/app/jobsninja-work/id6451492994",
                        "_blank"
                      );
                    }}
                  >
                    <img src={AppleStore} className="w-[80px] xl:w-[100px]" />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between gap-16 mt-8 md:mt-0">
              <div className="flex flex-col gap-2">
                <Link to="">
                  <p className="text-sm font-bold text-white">Company</p>
                </Link>
                <Link to="/">
                  <p className="text-sm font-normal text-textColorZero">Home</p>
                </Link>
                <Link to="/">
                  <p className="text-sm font-normal text-textColorZero">
                    For Worker
                  </p>
                </Link>

                <p className="text-xs font-normal text-textColorZero">
                  <span className="font-semibold text-sm">
                    Idia Corporation Pty Ltd
                  </span>
                  <br />
                  Suite 112, Waterman Caribbean Park, <br /> 44 Lakeview Drive,
                  Scoresby, <br /> Victoria 3179, Australia <br />
                  hello@idiacorp.com
                </p>
              </div>

              <div className="flex flex-col gap-2">
                <Link to="">
                  <p className="text-sm font-bold text-white">Legal</p>
                </Link>
                <Link to="/privacy">
                  <p className="text-sm font-normal text-textColorZero">
                    Privacy
                  </p>
                </Link>
                <Link to="/terms-condition">
                  <p className="text-sm font-normal text-textColorZero">
                    Terms
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
