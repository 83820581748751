import React, { useContext, useEffect, useState } from "react";
import JobpostLayout from "./jobpostLayout/JobpostLayout";
import DotIcon from "../../assets/svg/DotIcon";
import LocationIconCard from "../../assets/svg/LocationIconCard";
import HelperTextIcon from "../../assets/svg/HelperTextIcon";
import { Button, Col, ConfigProvider, Input, Radio, Row, Select } from "antd";
import DoneIcon from "../../assets/svg/DoneIcon";
import UrgentIcon from "../../assets/svg/UrgentIcon";
import CalendarIcon from "../../assets/svg/CalendarIcon";
import FlexibleIcon from "../../assets/svg/FlexibleIcon";
import CalendarGreenOutlineIcon from "../../assets/svg/CalendarGreenOutlineIcon";
import DiscriIcon from "../../assets/svg/DiscriIcon";
import TextArea from "antd/es/input/TextArea";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import DiscriptionIcon from "../../assets/svg/DiscriptionIcon";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { NotificationContext } from "../../context/NotificationContext";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { useNavigate } from "react-router-dom";
import CreateJobPostServices from "../../services/CreateJobPostServices";
import { SubscriptionContext } from "../../context/SubscriptionContext";

const JobDetails = () => {
  const navigate = useNavigate();
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    setStepperCurrentSteps,
    completedStep,
    setCompletedStep,

    // stepsOpenHandler,
    // setStepsOpenHandler,

    locationValues,
    setLocationValues,

    jobValues,
    setjobValues,
    descriptionValues,
    setdescriptionValues,

    countrySelectorData,
    setCountrySelectorData,
    location1SelectorData,
    setLocation1SelectorData,
    location2SelectorData,
    setLocation2SelectorData,

    selectedJobType,

    setTaskerList,

    allCountryValues,
    setAllCountryValues,
    selectedCountryType,
    setSelectedCountryType,
  } = useContext(CreateJobPostContext);
  const { fetchCountryCode, userLocation } = useContext(SubscriptionContext);

  const {
    getRelatedSearchedPostalCode,
    getAllCountries,
    getAllLocationLevel1Data,
    getAllLocationLevel2Data,
    getAllTakerList,
  } = CreateJobPostServices();

  const [postalCodeSelectorData, setPostalCodeSelectorData] = useState([]);
  const [postalCodeListWithAllData, setPostalCodeListWithAllData] = useState(
    []
  );

  const [selectedSuburb, setSelectedSuburb] = useState();

  useEffect(() => {
    fetchCountryCode();
    fetchCountryData();
  }, []);

  const fetchCountryData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data?.success) {
            setAllCountryValues(response?.data.output);
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = async (countries) => {
    //set default country according to the IP address
    const userCountryIDByIPAddress = await countries?.find(
      (item) => item?.country_code?.toLowerCase() === userLocation
    )?.country_id;

    setLocationValues({
      ...locationValues,
      country: userCountryIDByIPAddress,
    });

    //set postal code require or not
    setSelectedCountryType(
      allCountryValues?.find(
        (item) => item?.country_id === userCountryIDByIPAddress
      )?.postal_code_required
    );
    setLocation1SelectorData([]);
    setLocation2SelectorData([]);

    if (
      allCountryValues?.find(
        (item) => item?.country_id === userCountryIDByIPAddress
      )?.postal_code_required === 0
    ) {
      fetchLocationLevel1Data(userCountryIDByIPAddress);
    }

    //set country selector dropdown values
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  const fetchLocationLevel2Data = async (data) => {
    try {
      await getAllLocationLevel2Data({
        country_id: locationValues?.country,
        level1_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel2Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel2Data = (locations2) => {
    var locationLevel2Object = [];
    locations2?.forEach((location2) => {
      let data = {
        value: location2?.level2_id,
        label: location2?.level2_location,
      };
      locationLevel2Object.push(data);
    });
    setLocation2SelectorData(locationLevel2Object);
  };

  const handleGetRelatedPostalCode = async (e) => {
    try {
      await getRelatedSearchedPostalCode({
        country_id: locationValues?.country,
        postal_code: e,
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setPostalCodeListWithAllData(response?.data.output);
            setPostalCodeData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setPostalCodeData = (items) => {
    var postalCodeObject = [];
    items?.forEach((item) => {
      let data = {
        value: item?.suberb_id,
        label: item?.postal_code + ", " + item?.suberb + ", " + item?.state,
      };
      postalCodeObject.push(data);
    });
    setPostalCodeSelectorData(postalCodeObject);
  };

  const fetchAllTaskerListData = async () => {
    try {
      await getAllTakerList({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        country_id: locationValues?.country,
        state_id:
          selectedCountryType === 1
            ? locationValues?.state
            : locationValues?.district,
        suburb_id:
          selectedCountryType === 1
            ? locationValues?.suburb
            : locationValues?.city,
        language_ids: [],
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList(response?.data.output);
          } else {
            setTaskerList([]);
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  useEffect(() => {
    setTaskerList([]);
    setStepperCurrentSteps(0);
    setCompletedStep(0);
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            controlHeight: 38,
            colorBorder: "rgb(123,123,123)",
          },
          Input: {
            controlHeight: 38,
            colorBorder: "rgb(123,123,123)",
          },
        },
      }}
    >
      <JobpostLayout>
        <div>
          <div className="flex flex-col justify-center items-center pt-5 md:pt-10">
            <h1 className="text-xl md:text-[26px] font-medium text-textColorOne">
              Create your Job post
            </h1>
            <p className="text-center text-sm text-textColorTwo pt-1 md:pt-2">
              Tell us about your task. We use these details to show Workers in
              your area who fit your needs.
            </p>
          </div>

          <div className="pt-3 md:pt-5">
            <div className="flex flex-row justify-start items-center gap-2 pl-2">
              <DotIcon width="8" color="#40D37B" />
              <p className="text-xs md:text-sm font-normal text-textColorTwo">
                Job type :
              </p>
              <p className="text-lg md:text-xl font-semibold text-textColorOne">
                {selectedJobType}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5 mt-2 md:mt-5">
          {/* About location */}
          <div className="p-4 rounded-lg border-[2px] pb-6 bg-white">
            <div className="flex flex-row justify-between items-center">
              <p className="flex flex-row items-center gap-1 text-sm md:text-base font-medium">
                <div>
                  <LocationIconCard />
                </div>
                Location of Your Job *
              </p>

              {locationValues?.country !== undefined ? (
                <>
                  {selectedCountryType === 1 ? (
                    <>
                      {locationValues?.postalCodetype1 !== undefined ? (
                        <DoneIcon />
                      ) : (
                        <HelperTextIcon />
                      )}
                    </>
                  ) : (
                    <>
                      {locationValues?.district !== undefined &&
                      locationValues?.city !== undefined ? (
                        <DoneIcon />
                      ) : (
                        <HelperTextIcon />
                      )}
                    </>
                  )}
                </>
              ) : (
                <HelperTextIcon />
              )}
            </div>

            <div className="flex justify-center items-center">
              <div className="w-full max-w-[1000px]">
                <div className="mt-2 md:mt-5 flex">
                  <Row className="w-full" gutter={8}>
                    <Col
                      span={24}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      className="pb-3 lg:pb-0"
                    >
                      <Select
                        autoFocus
                        className="w-full"
                        placeholder="Select country"
                        showSearch
                        value={locationValues?.country}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={countrySelectorData}
                        onSelect={(e) => {
                          setLocationValues({
                            country: e,
                            countryName: countrySelectorData.find(
                              (item) => item?.value === e
                            )?.label,
                            state: undefined,
                            suburb: undefined,

                            postalCodetype1: undefined,
                            postalCodetype2: undefined,

                            district: undefined,
                            districtName: undefined,
                            city: undefined,
                            cityName: undefined,
                          });

                          //set postal code require or not
                          setSelectedCountryType(
                            allCountryValues?.find(
                              (item) => item?.country_id === e
                            )?.postal_code_required
                          );
                          setLocation1SelectorData([]);
                          setLocation2SelectorData([]);

                          if (
                            allCountryValues?.find(
                              (item) => item?.country_id === e
                            )?.postal_code_required === 0
                          ) {
                            fetchLocationLevel1Data(e);
                          }
                        }}
                      />
                    </Col>

                    {selectedCountryType === 1 ? (
                      <>
                        <Col
                          span={24}
                          md={{ span: 12 }}
                          lg={{ span: 8 }}
                          className="pb-3 lg:pb-0"
                        >
                          <Select
                            className="w-full"
                            placeholder="Search postal code"
                            showSearch
                            value={locationValues?.postalCodetype1}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={postalCodeSelectorData}
                            onSearch={(e) => {
                              if (e?.length === 4) {
                                handleGetRelatedPostalCode(e);
                              }
                            }}
                            onSelect={async (e) => {
                              setSelectedSuburb(e);

                              const selectedLocation =
                                await postalCodeListWithAllData?.find(
                                  (item) => item?.suberb_id === e
                                );

                              setLocationValues({
                                ...locationValues,
                                state: selectedLocation?.state_id,
                                suburb: selectedLocation?.suberb_id,
                                postalCodetype1: postalCodeSelectorData?.find(
                                  (item) => item.value === e
                                )?.label,
                                postalCodetype2:
                                  selectedLocation?.state +
                                  ", " +
                                  selectedLocation?.suberb +
                                  ", " +
                                  selectedLocation?.postal_code,
                              });
                            }}
                          />
                        </Col>

                        <Col
                          span={24}
                          md={{ span: 12 }}
                          lg={{ span: 8 }}
                          className="pb-3 lg:pb-0"
                        >
                          <Input
                            placeholder="State"
                            disabled
                            value={
                              postalCodeListWithAllData?.find(
                                (item) => item?.suberb_id === selectedSuburb
                              )?.state
                            }
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col
                          span={24}
                          md={{ span: 12 }}
                          lg={{ span: 8 }}
                          className="pb-3 lg:pb-0"
                        >
                          <Select
                            className="w-full"
                            placeholder={
                              allCountryValues?.find(
                                (item) =>
                                  item?.country_id === locationValues?.country
                              )?.level_1_label
                            }
                            showSearch
                            value={locationValues?.district}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={location1SelectorData}
                            onSelect={(e) => {
                              fetchLocationLevel2Data(e);
                              setLocationValues({
                                ...locationValues,
                                district: e,
                                districtName: location1SelectorData.find(
                                  (item) => item?.value === e
                                )?.label,
                              });
                            }}
                          />
                        </Col>

                        <Col
                          span={24}
                          md={{ span: 12 }}
                          lg={{ span: 8 }}
                          className="pb-3 lg:pb-0"
                        >
                          <Select
                            className="w-full"
                            placeholder={
                              allCountryValues?.find(
                                (item) =>
                                  item?.country_id === locationValues?.country
                              )?.level_2_label
                            }
                            showSearch
                            value={locationValues?.city}
                            options={location2SelectorData}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onSelect={(e) => {
                              setLocationValues({
                                ...locationValues,
                                city: e,
                                cityName: location2SelectorData.find(
                                  (item) => item?.value === e
                                )?.label,
                              });
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </div>

          {/* About Urgency */}
          <div className="p-4 rounded-lg border-[2px] bg-white">
            <div className="flex flex-row justify-between items-center">
              <p className="flex flex-row items-center gap-1 text-sm md:text-base font-medium">
                <div>
                  <CalendarGreenOutlineIcon />
                </div>
                Job urgency *
              </p>
              {jobValues?.jobOne || jobValues?.jobTwo || jobValues?.jobThree ? (
                <DoneIcon />
              ) : (
                <HelperTextIcon />
              )}
            </div>

            <div className="flex justify-center items-center">
              <div className="w-full max-w-[1000px]">
                <div className="mt-2 md:mt-5 flex">
                  <div className="w-full flex flex-col sm:flex-row gap-2">
                    <div className="w-full flex flex-col gap-1">
                      <div
                        className="border-[1px] border-[rgb(123,123,123)] flex flex-row justify-between items-center w-full p-1 rounded-lg cursor-pointer"
                        onClick={() => {
                          setjobValues({
                            jobOne: true,
                            jobTwo: false,
                            jobThree: false,
                          });
                        }}
                      >
                        <div className="flex flex-row gap-2 justify-start items-center pl-1">
                          <UrgentIcon />
                          <div className="border-r-[2px] h-7"></div>
                          <p className="text-xs font-normal">Urgent</p>
                        </div>

                        <Radio
                          checked={jobValues?.jobOne}
                          onChange={() => {
                            setjobValues({
                              jobOne: true,
                              jobTwo: false,
                              jobThree: false,
                            });
                          }}
                        />
                      </div>

                      <p className="flex flex-row gap-1 text-xs font-normal text-textColorTwo">
                        <DiscriIcon />
                        with in next 72 hours
                      </p>
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <div
                        className="border-[1px] border-[rgb(123,123,123)] flex flex-row justify-between items-center w-full p-1 rounded-lg cursor-pointer"
                        onClick={() => {
                          setjobValues({
                            jobOne: false,
                            jobTwo: true,
                            jobThree: false,
                          });
                        }}
                      >
                        <div className="flex flex-row gap-2 justify-start items-center pl-1">
                          <CalendarIcon />
                          <div className="border-r-[2px] h-7"></div>
                          <p className="text-xs font-normal">Within few days</p>
                        </div>

                        <Radio
                          checked={jobValues?.jobTwo}
                          onChange={() => {
                            setjobValues({
                              jobOne: false,
                              jobTwo: true,
                              jobThree: false,
                            });
                          }}
                        />
                      </div>
                      <p className="flex flex-row gap-1 text-xs font-normal text-textColorTwo">
                        <DiscriIcon />
                        with in next 3 - 7 days
                      </p>
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <div
                        className="border-[1px] border-[rgb(123,123,123)] flex flex-row justify-between items-center w-full p-1 rounded-lg cursor-pointer"
                        onClick={() => {
                          setjobValues({
                            jobOne: false,
                            jobTwo: false,
                            jobThree: true,
                          });
                        }}
                      >
                        <div className="flex flex-row gap-2 justify-start items-center pl-1">
                          <FlexibleIcon />
                          <div className="border-r-[2px] h-7"></div>
                          <p className="text-xs font-normal">I’m flexible</p>
                        </div>

                        <Radio
                          checked={jobValues?.jobThree}
                          onChange={() => {
                            setjobValues({
                              jobOne: false,
                              jobTwo: false,
                              jobThree: true,
                            });
                          }}
                        />
                      </div>
                      <p className="flex flex-row gap-1 text-xs font-normal text-textColorTwo">
                        <DiscriIcon />
                        Not urgent
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* About description */}
          <div className="p-4 rounded-lg border-[2px] bg-white">
            <div className="flex flex-row justify-between items-center">
              <p className="flex flex-row items-center gap-1 text-sm md:text-base font-medium">
                <div>
                  <DiscriptionIcon />
                </div>
                Description *
              </p>
              {descriptionValues !== "" ? <DoneIcon /> : <HelperTextIcon />}
            </div>

            <div className="flex justify-center items-center">
              <div className="w-full max-w-[1000px]">
                <div className="mt-2 md:mt-5 flex">
                  <div className="w-full">
                    <TextArea
                      value={descriptionValues}
                      placeholder="Describe about your job..."
                      className="text-xs font-normal"
                      rows={6}
                      onChange={(e) => {
                        setdescriptionValues(e?.target?.value);
                      }}
                    />
                  </div>
                </div>

                <div className="pt-5 flex justify-end">
                  <Button
                    type="primary"
                    className="bg-primaryDark font-semibold px-8 flex flex-row gap-2 justify-center items-center"
                    onClick={() => {
                      if (getLocalStoragedata("token")) {
                        fetchAllTaskerListData();
                        setCompletedStep(1);
                        setStepperCurrentSteps(1);
                      } else {
                        navigate("../login", {
                          state: { role: 2, type: "asClient" },
                        });
                      }
                    }}
                    disabled={
                      locationValues?.country === undefined ||
                      (selectedCountryType === 1 &&
                        locationValues?.postalCodetype1 === undefined) ||
                      (selectedCountryType === 0 &&
                        (locationValues?.district === undefined ||
                          locationValues?.city === undefined)) ||
                      (jobValues?.jobOne ||
                        jobValues?.jobTwo ||
                        jobValues?.jobThree) !== true ||
                      descriptionValues === ""
                    }
                  >
                    Next <MdOutlineKeyboardArrowRight />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5"></div>
        </div>
      </JobpostLayout>
    </ConfigProvider>
  );
};

export default JobDetails;
