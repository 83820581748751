import React, { useContext, useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import SubscriptionDetail from "../assets/img/subscriptionDetails.png";
import PremiumGreenIcon from "../assets/svg/PremiumGreenIcon";
import { Col, Divider, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { SubscriptionContext } from "../context/SubscriptionContext";

const SubscriptionDetails = () => {
  let { userLocation, fetchCountryCode } = useContext(SubscriptionContext);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCountryCode();
  }, []);

  const detailArray = [
    "Unlimited leads per months",
    "Sharable public profile & personalized QR",
    "Expended profile visibility",
    "Favorite clients & repeat jobs",
    "Direct calls",
    "and more premium features",
  ];

  return (
    <div>
      <NavBar />
      <div className="content">
        <div className="flex flex-col w-full justify-center items-center my-28">
          <div className="max-w-[1073px] xl:w-[1073px] w-full">
            <Row gutter={16}>
              <Col span={24} md={{ span: 14 }} className="flex justify-center">
                <img src={SubscriptionDetail} className="w-[550px] h-[600px]" />
              </Col>

              <Col
                span={24}
                md={{ span: 10 }}
                className="flex flex-col justify-center items-start max-w-[500px] md:w-full"
              >
                {["au", "nz", "sg"].includes(userLocation) ? (
                  <>
                    <p className="text-xl md:text-2xl text-textColorTwo font-semibold">
                      JobsNinja Premium
                    </p>

                    <p className="text-[64px] font-bold text-blue-600">
                      $65 <span className="text-3xl">/Per month</span>
                    </p>

                    <button
                      className="bg-blue-600 text-white text-sm font-semibold rounded-md py-2 w-full"
                      onClick={() => {
                        navigate("../login", {
                          state: { role: 1, type: "asPayment" },
                        });
                      }}
                    >
                      Update Subscription
                    </button>
                  </>
                ) : (
                  <p className="text-xl md:text-2xl text-textColorTwo font-semibold">
                    Now with JobsNinja
                  </p>
                )}

                <Divider className="bg-textColorTwo" />

                {detailArray?.map((item) => {
                  return (
                    <p className="flex flex-row justify-center items-center gap-2 text-sm md:text-base font-medium">
                      <PremiumGreenIcon />
                      {item}
                    </p>
                  );
                })}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
