import { getLocalStoragedata } from "./encryptHelpers/storageHelper";

const HeaderConfig = () => {
  const baseUrl = process.env.REACT_APP_URL;
  const token = getLocalStoragedata("token");

  const configHeader = {
    headers: {
      "Access-Control-Allow": true,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const configHeaderForOTP = {
    headers: {
      "Access-Control-Allow": true,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "App-Type": 1,
    },
  };

  const configHeaderDiff = {
    headers: {
      "Access-Control-Allow": true,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "App-Type": 1,
    },
  };

  const configAuth = {
    headers: {
      "Access-Control-Allow": true,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Origin: baseUrl,
      "X-Custom-Header": "random-value-1234567890",
      Authorization: `Bearer ${token}`,
    },
  };

  return {
    token,
    baseUrl,
    configHeader,
    configHeaderForOTP,
    configHeaderDiff,
    configAuth,
  };
};

export default HeaderConfig;
