import React, { useContext, useState } from "react";
import JobpostLayout from "./jobpostLayout/JobpostLayout";
import { Button, Col, Divider, Dropdown, Row } from "antd";
import LocationIconFull from "../../assets/svg/LocationIconFull";
import { FaAngleRight, FaCaretDown } from "react-icons/fa";
import VerifyIcon from "../../assets/svg/VerifyIcon";
import ViewsIcon from "../../assets/svg/ViewsIcon";
import CompleteIcon from "../../assets/svg/CompleteIcon";
import PremiumIcon from "../../assets/svg/PremiumIcon";
import LanguageFullIcon from "../../assets/svg/LanguageFullIcon";
import VIewTaskerProfile from "./VIewTaskerProfile";
import UpArrow from "../../assets/svg/UpArrow";
import DownArrowIcon from "../../assets/svg/DownArrowIcon";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import NewWorkerIcon from "../../assets/svg/NewWorkerIcon";
import UserColorProfile from "../../components/userColorProfile/UserColorProfile";
import { getDateDifference } from "../../utils/dateDifference";
import { getLocalStoragedata } from "../../helpers/encryptHelpers/storageHelper";
import { NotificationContext } from "../../context/NotificationContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";

const SelectedTaskers = () => {
  const [language, setLanguage] = useState("All");
  const [sortValue, setSortValue] = useState("Recommended");

  const [openCloseTaskerProfile, setOpenCloseTaskerProfile] = useState(false);
  const [openJobDetails, setOpenJobDetails] = useState(false);
  const [selectedTaskerData, setSelectedTaskerData] = useState();

  let {
    selectedJobType,
    locationValues,
    jobValues,
    descriptionValues,

    taskerList,
    setTaskerList,

    selectedCountryType,
  } = useContext(CreateJobPostContext);
  const { openNotification, handleError } = useContext(NotificationContext);

  const { getAllTakerList } = CreateJobPostServices();

  const handleOpenCloseTaskerProfile = () => {
    setOpenCloseTaskerProfile(false);
  };

  const languageItems = [
    {
      label: "All",
      key: 1,
    },
    {
      label: "English",
      key: 2,
    },
    {
      label: "Sinhala",
      key: 3,
    },
    {
      label: "Tamil",
      key: 4,
    },
  ];
  const handleSelectLanguage = async ({ key }) => {
    setLanguage(
      languageItems?.find((item) => {
        return item?.key == key;
      })?.label
    );

    try {
      await getAllTakerList({
        job_type_id: getLocalStoragedata("searchSelectedJobTypeId"),
        country_id: locationValues?.country,
        state_id:
          selectedCountryType === 1
            ? locationValues?.state
            : locationValues?.district,
        suburb_id:
          selectedCountryType === 1
            ? locationValues?.suburb
            : locationValues?.city,
        language_ids: Number(key) === 1 ? [] : [Number(key)],
      })
        .then((response) => {
          if (response?.data?.success) {
            setTaskerList(response?.data.output);
          } else {
            openNotification("warning", response?.data?.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const sortItems = [
    {
      label: "Recommended",
      key: "0",
    },
    {
      label: "price (Lowest to Highest)",
      key: "1",
    },
    {
      label: "price (Highest to Lowest )",
      key: "2",
    },
    {
      label: "Positive Reviews",
      key: "3",
    },
    {
      label: "Number Of Completed Tasks",
      key: "4",
    },
  ];
  const handleSelectSort = ({ key }) => {
    setSortValue(
      sortItems?.find((item) => {
        return item?.key == key;
      })?.label
    );
  };

  return (
    <>
      <JobpostLayout>
        <div>
          <div className="flex flex-col justify-center items-center pt-5 md:pt-10">
            <Row
              className="flex flex-row w-full mb-[150px] lg:mb-[10px]"
              gutter={16}
            >
              <Col span={0} lg={{ span: 8 }}>
                <p className="text-xs font-medium text-primaryColor">
                  Job Details
                </p>
                <div className="w-full mt-5 rounded-lg p-2 bg-white">
                  <div>
                    <p className="text-xs font-normal text-textColorTwo">
                      Job type & Location :
                    </p>
                    <p className="text-sm font-semibold text-textColorOne">
                      {selectedJobType}
                    </p>
                    <div className="flex flex-row">
                      <LocationIconFull />
                      <p className="text-xs font-normal text-textColorOne">
                        {selectedCountryType === 1
                          ? locationValues?.postalCodetype2
                          : locationValues?.districtName +
                            ", " +
                            locationValues?.cityName +
                            ", " +
                            locationValues?.countryName +
                            "."}
                      </p>
                    </div>
                  </div>
                  <Divider className="my-2" />

                  <div>
                    <p className="text-xs font-normal text-textColorTwo">
                      Job Urgency :
                    </p>
                    <p className="text-xs font-semibold text-textColorOne">
                      {jobValues?.jobOne
                        ? "Urgent"
                        : jobValues?.jobTwo
                        ? "Within few days"
                        : "I’m flexible"}
                    </p>
                  </div>
                  <Divider className="my-2" />

                  <div>
                    <p className="text-xs font-normal text-textColorTwo">
                      Description
                    </p>
                    <p className="text-xs font-semibold text-textColorOne">
                      {descriptionValues}
                    </p>
                  </div>
                </div>
              </Col>

              <Col span={24} lg={{ span: 16 }}>
                <div className="flex flex-col xsm:flex-row justify-between">
                  <p className="text-xs font-medium text-primaryColor">
                    {taskerList?.length} worker(s) found
                  </p>

                  <div className="flex flex-row gap-2 justify-end items-end">
                    <Dropdown
                      menu={{
                        items: languageItems,
                        onClick: handleSelectLanguage,
                      }}
                    >
                      <div className="flex flex-row gap-1 py-1 px-2 justify-start items-center rounded-md bg-white">
                        <p className="text-xs font-normal text-textColorThree hidden md:block">
                          Preferred Language :
                        </p>
                        <div className="flex flex-row justify-between items-center gap-5 text-primaryDarkest">
                          <p className="text-xs font-semibold">{language}</p>
                          <FaCaretDown />
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>

                {taskerList?.length !== 0 ? (
                  <div>
                    {taskerList?.map((item) => {
                      return (
                        <div className="w-full mt-3 rounded-lg p-2 bg-white">
                          <Row className="flex flex-row">
                            <Col
                              span={24}
                              md={{ span: 6 }}
                              className="flex flex-col justify-start md:justify-center md:items-center pt-5 md:py-10"
                            >
                              <div className="hidden md:block">
                                <div className="flex flex-row items-center justify-center gap-1">
                                  {item?.profile_picture === null ? (
                                    <UserColorProfile
                                      name={
                                        decryptSecureData(item?.first_name) ||
                                        "N Z"
                                      }
                                      color={"#3bb96e"}
                                      size="100px"
                                      textSize="40px"
                                    />
                                  ) : (
                                    <img
                                      src={item?.profile_picture}
                                      alt=""
                                      className="flex w-[100px] h-[100px] rounded-full border-[2px]"
                                    />
                                  )}
                                </div>

                                <div className="flex flex-col gap-2 mt-2">
                                  {item?.has_verified === 1 && (
                                    <div className="flex flex-row gap-3 justify-start items-center">
                                      <VerifyIcon />
                                      <p className="text-sm font-medium text-textColorTwo">
                                        Verified Worker
                                      </p>
                                    </div>
                                  )}

                                  {item?.rate !== 0 && (
                                    <div className="flex flex-row gap-3 justify-start items-center">
                                      <ViewsIcon />
                                      <p className="text-sm font-medium text-textColorTwo">
                                        <span className="text-sm font-bold text-textColorOne">
                                          {" "}
                                          {item?.rate}
                                        </span>{" "}
                                        ({item?.review_count} Reviews)
                                      </p>
                                    </div>
                                  )}

                                  {item?.job_count !== 0 && (
                                    <div className="flex flex-row gap-3 justify-start items-center">
                                      <CompleteIcon />
                                      <p className="text-sm font-medium text-textColorTwo">
                                        <span className="text-sm font-bold text-textColorOne">
                                          {" "}
                                          {item?.job_count}+
                                        </span>{" "}
                                        Completed Tasks
                                      </p>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="flex flex-row justify-center items-center gap-2 underline text-primaryDarkest mt-5 cursor-pointer"
                                  onClick={() => {
                                    setOpenCloseTaskerProfile(true);
                                    setSelectedTaskerData(item);
                                  }}
                                >
                                  <p className="text-sm font-medium">
                                    View profile
                                  </p>
                                  <FaAngleRight className="text-primaryDarkest" />
                                </div>
                              </div>

                              <div className="flex md:hidden flex-col sm:flex-row gap-10 w-full">
                                <div className="flex flex-col justify-center items-center">
                                  {item?.profile_picture === null ? (
                                    <UserColorProfile
                                      name={
                                        decryptSecureData(item?.first_name) ||
                                        "N Z"
                                      }
                                      color={"#3bb96e"}
                                      size="100px"
                                      textSize="40px"
                                    />
                                  ) : (
                                    <img
                                      src={item?.profile_picture}
                                      alt=""
                                      className="flex max-w-[100px] h-[100px] rounded-full border-[2px]"
                                    />
                                  )}
                                </div>

                                <div className="flex flex-col sm:flex-row justify-between w-full">
                                  <div className="flex flex-col gap-2">
                                    {item?.has_verified === 1 && (
                                      <div className="flex flex-row gap-3 justify-start items-center">
                                        <VerifyIcon />
                                        <p className="text-sm font-medium text-textColorTwo">
                                          Verified Worker
                                        </p>
                                      </div>
                                    )}

                                    {item?.rate !== 0 && (
                                      <div className="flex flex-row gap-3 justify-start items-center">
                                        <ViewsIcon />
                                        <p className="text-sm font-medium text-textColorTwo">
                                          <span className="text-sm font-bold text-textColorOne">
                                            {" "}
                                            {item?.rate}
                                          </span>{" "}
                                          ({item?.review_count} Reviews)
                                        </p>
                                      </div>
                                    )}

                                    {item?.job_count !== 0 && (
                                      <div className="flex flex-row gap-3 justify-start items-center">
                                        <CompleteIcon />
                                        <p className="text-sm font-medium text-textColorTwo">
                                          <span className="text-sm font-bold text-textColorOne">
                                            {" "}
                                            {item?.job_count}+
                                          </span>{" "}
                                          Completed Tasks
                                        </p>
                                      </div>
                                    )}

                                    <div
                                      className="flex flex-row justify-start items-center gap-2 underline text-primaryDarkest cursor-pointer"
                                      onClick={() => {
                                        setOpenCloseTaskerProfile(true);
                                        setSelectedTaskerData(item);
                                      }}
                                    >
                                      <p className="text-sm font-medium">
                                        View profile
                                      </p>
                                      <FaAngleRight className="text-primaryDarkest" />
                                    </div>
                                  </div>

                                  {/* <div className="flex justify-start pt-3 sm:pt-0 sm:justify-end sm:items-end">
                                    <Button
                                      type="primary"
                                      className="text-sm font-medium bg-primaryDark px-10"
                                    >
                                      Select
                                    </Button>
                                  </div> */}
                                </div>
                              </div>
                            </Col>

                            <Col
                              span={0}
                              md={{ span: 1 }}
                              className="flex justify-center items-center"
                            >
                              <Divider
                                type="vertical"
                                className="bg-gray-300 h-[90%]"
                              />
                            </Col>

                            <Col span={24} md={{ span: 17 }}>
                              <div className="py-3">
                                <div className="flex flex-row justify-between items-center">
                                  <div className="flex flex-row gap-3">
                                    <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                                      {decryptSecureData(item?.first_name)}
                                    </p>
                                    <div
                                      className={`border-[2px] ${
                                        item?.package_id === 1
                                          ? "border-[#E79721] bg-[#E7972133]"
                                          : "border-[#D659D9] bg-[#D659D933]"
                                      }  px-2 rounded-lg flex flex-row justify-center items-center gap-1`}
                                    >
                                      {item?.package_id === 1 ? (
                                        <NewWorkerIcon />
                                      ) : (
                                        <PremiumIcon />
                                      )}

                                      <p
                                        className={`text-xxs font-bold ${
                                          item?.package_id === 1
                                            ? "text-[#E79721]"
                                            : "text-[#D659D9]"
                                        }  hidden xsm:block`}
                                      >
                                        {item?.package_id === 1
                                          ? "NEW WORKER"
                                          : " PREMIUM"}
                                      </p>
                                    </div>
                                  </div>

                                  {/* <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                              25$ /Hr
                            </p> */}
                                </div>
                              </div>

                              <div>
                                <p className="text-xs font-normal text-textColorTwo">
                                  {item?.work_title}
                                </p>
                                <div className="flex flex-row">
                                  <LocationIconFull />
                                  <p className="text-xs font-semibold text-textColorOne">
                                    {[
                                      item?.state,
                                      item?.suberb,
                                      item?.postal_code,
                                    ]
                                      .filter(Boolean)
                                      .join(", ")}{" "}
                                  </p>
                                </div>

                                {item?.language !== "" ||
                                  item?.language !== null ||
                                  (item?.language !== undefined && (
                                    <div className="flex flex-row">
                                      <LanguageFullIcon />
                                      <p className="text-xs font-semibold text-textColorOne">
                                        {item?.language}
                                      </p>
                                    </div>
                                  ))}
                              </div>

                              <Row className="flex flex-row gap-1 mt-2">
                                {item?.job_types?.map((item) => {
                                  return (
                                    <Col>
                                      <p className="border-[1px] border-gray-300 py-1 px-2 rounded-full text-xs font-normal text-primaryDarkest">
                                        {item?.job_type}
                                      </p>
                                    </Col>
                                  );
                                })}
                              </Row>
                              <Divider className="my-3" />

                              <div>
                                <p className="text-sm font-bold text-textColorOne">
                                  Skills & Experience
                                </p>
                                {item?.work_description === null ||
                                item?.work_description === "" ? (
                                  <p className="text-sm font-bold text-textColorTwo">
                                    _
                                  </p>
                                ) : (
                                  <p className="text-xs font-normal text-textColorTwo">
                                    {item?.work_description}
                                  </p>
                                )}
                              </div>
                              <Divider className="my-3" />

                              <div>
                                <p className="text-sm font-bold text-textColorOne">
                                  Latest reviews
                                </p>

                                {item?.rates?.length === 0 ? (
                                  <p className="text-sm font-bold text-textColorTwo">
                                    _
                                  </p>
                                ) : (
                                  <div className="max-h-[200px] overflow-y-scroll scrollbar-hide">
                                    {item?.rates?.map((item) => {
                                      return (
                                        <div className="pt-3 flex flex-row gap-3 w-full">
                                          <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                                            {item?.profile_picture === null ? (
                                              <UserColorProfile
                                                name={
                                                  decryptSecureData(
                                                    item?.customer_name
                                                  ) || "N Z"
                                                }
                                                color={"#3bb96e"}
                                                size="40px"
                                                textSize="16px"
                                              />
                                            ) : (
                                              <img
                                                src={item?.profile_pictur}
                                                alt=""
                                                className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                                              />
                                            )}
                                          </div>

                                          <div className="w-full">
                                            <div className="flex flex-row justify-between items-center w-full">
                                              <p className="text-xs font-semibold">
                                                {decryptSecureData(
                                                  item?.customer_name
                                                )}{" "}
                                                <span className="font-normal text-textColorTwo">
                                                  {getDateDifference(
                                                    item?.rated_date_time
                                                  )}
                                                </span>
                                              </p>

                                              <div className="flex flex-row gap-2">
                                                <p className="text-xs font-semibold">
                                                  {item?.rate}
                                                </p>
                                                <div className="flex flex-row gap-1">
                                                  {Array.from(
                                                    { length: item?.rate },
                                                    (_, i) => (
                                                      <ViewsIcon key={i} />
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            <p className="text-xxs font-normal text-textColorTwo">
                                              {item?.message}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full mt-3 rounded-lg p-2 bg-white">
                    <p className="text-center text-sm font-bold text-textColorTwo">
                      No Workers
                    </p>
                  </div>
                )}
              </Col>
            </Row>

            <div className="fixed -bottom-5 left-0 w-full my-5 px-4 block lg:hidden">
              <div
                className="flex flex-col rounded-lg p-4 relative bg-white"
                style={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                }}
              >
                <div className="overflow-scroll">
                  <div className="max-h-[400px]">
                    <div
                      onClick={() => {
                        setOpenJobDetails(!openJobDetails);
                      }}
                    >
                      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-1">
                        <div className="flex items-center justify-center w-6 h-6">
                          {openJobDetails ? <DownArrowIcon /> : <UpArrow />}
                        </div>
                      </div>

                      <div
                        className={`transition-max-height duration-500 ease-in-out ${
                          openJobDetails ? "max-h-[500px]" : "max-h-20"
                        }`}
                      >
                        {openJobDetails && (
                          <>
                            <div>
                              <p className="text-xs font-medium text-primaryDark mb-3">
                                Job Details
                              </p>

                              <p className="text-xs font-normal text-textColorTwo">
                                Job type & Location :
                              </p>
                              <p className="text-sm font-semibold text-textColorOne">
                                {selectedJobType}
                              </p>
                              <div className="flex flex-row">
                                <LocationIconFull />
                                <p className="text-xs font-normal text-textColorOne">
                                  {selectedCountryType === 1
                                    ? locationValues?.postalCodetype2
                                    : locationValues?.districtName +
                                      ", " +
                                      locationValues?.cityName +
                                      ", " +
                                      locationValues?.countryName +
                                      "."}
                                </p>
                              </div>
                            </div>
                            <Divider className="my-2 bg-textColorZero" />

                            <div>
                              <p className="text-xs font-normal text-textColorTwo">
                                Job Urgency :
                              </p>
                              <p className="text-xs font-semibold text-textColorOne">
                                {jobValues?.jobOne
                                  ? "Urgent"
                                  : jobValues?.jobTwo
                                  ? "Within few days"
                                  : "I’m flexible"}
                              </p>
                            </div>
                            <Divider className="my-2 bg-textColorZero" />

                            <div>
                              <p className="text-xs font-normal text-textColorTwo">
                                Description
                              </p>
                              <p className="text-xs font-semibold text-textColorOne">
                                {descriptionValues}
                              </p>
                            </div>
                            <Divider className="my-2 bg-textColorZero" />
                          </>
                        )}

                        {!openJobDetails && (
                          <div className="flex flex-row justify-between text-xxs font-medium text-primaryDark">
                            <p>Job Details</p>
                            <p>{selectedJobType}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <Button
                      type="primary"
                      className="w-full bg-primaryDarkest mt-5"
                      onClick={() => {
                        setOpenJobDetails(!openJobDetails);
                      }}
                    >
                      View more Details
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </JobpostLayout>

      {openCloseTaskerProfile && (
        <VIewTaskerProfile
          open={openCloseTaskerProfile}
          onClose={handleOpenCloseTaskerProfile}
          selectedTaskerData={selectedTaskerData}
        />
      )}
    </>
  );
};

export default SelectedTaskers;
