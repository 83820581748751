import React from "react";

const UpArrow = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="19.5"
        height="19.5"
        rx="9.75"
        stroke="#2C8B52"
        strokeWidth="1.125"
      />
      <path
        d="M13.6 12.175L10.675 9.25L7.75 12.175"
        stroke="#2C8B52"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpArrow;
