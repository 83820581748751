import { Col, Divider, Drawer, Row } from "antd";
import React from "react";
import CloseIcon from "../../assets/svg/CloseIcon";
import PremiumIcon from "../../assets/svg/PremiumIcon";
import LocationIconFull from "../../assets/svg/LocationIconFull";
import LanguageFullIcon from "../../assets/svg/LanguageFullIcon";
import VerifyIcon from "../../assets/svg/VerifyIcon";
import ViewsIcon from "../../assets/svg/ViewsIcon";
import CompleteIcon from "../../assets/svg/CompleteIcon";
import UserColorProfile from "../../components/userColorProfile/UserColorProfile";
import { decryptSecureData } from "../../helpers/encryptHelpers/encryption";
import NewWorkerIcon from "../../assets/svg/NewWorkerIcon";
import { getDateDifference } from "../../utils/dateDifference";
import WorkSampleImageList from "../../components/workSampleImageList/WorkSampleImageList";

const VIewTaskerProfile = ({ open, onClose, selectedTaskerData }) => {
  return (
    <Drawer open={open} onClose={onClose} width={"large"} closeIcon={false}>
      <div className="w-full md:w-[700px] admin-page">
        <div
          className="flex justify-end"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon color="#4d5056" />
        </div>

        <div className="px-1 md:px-5">
          {/* <div className="flex justify-end pt-5 pb-5">
            <Button type="primary" className="bg-primaryDark px-7">
              Select
            </Button>
          </div> */}

          <div className="flex flex-col md:flex-row gap-5 justify-start w-full">
            <div className="w-full md:w-[30%]">
              <div className="flex flex-col justify-center items-center">
                {selectedTaskerData?.profile_picture === null ? (
                  <UserColorProfile
                    name={
                      decryptSecureData(selectedTaskerData?.first_name) || "N Z"
                    }
                    color={"#3bb96e"}
                    size="100px"
                    textSize="40px"
                  />
                ) : (
                  <img
                    src={selectedTaskerData?.profile_picture}
                    alt=""
                    className="flex w-[100px] h-[100px] rounded-full border-[2px]"
                  />
                )}

                {selectedTaskerData?.has_verified === 1 && (
                  <div className="hidden md:flex flex-row gap-3 justify-start items-center pt-2">
                    <VerifyIcon />
                    <p className="text-sm font-medium text-textColorTwo">
                      Verified Worker
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3">
                  <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                    {decryptSecureData(selectedTaskerData?.first_name)}
                  </p>
                  <div
                    className={`border-[2px] ${
                      selectedTaskerData?.package_id === 1
                        ? "border-[#E79721] bg-[#E7972133]"
                        : "border-[#D659D9] bg-[#D659D933]"
                    } px-2 rounded-lg flex flex-row justify-center items-center gap-1`}
                  >
                    {selectedTaskerData?.package_id === 1 ? (
                      <NewWorkerIcon />
                    ) : (
                      <PremiumIcon />
                    )}
                    <p
                      className={`text-xxs font-bold ${
                        selectedTaskerData?.package_id === 1
                          ? "text-[#E79721]"
                          : "text-[#D659D9]"
                      } hidden md:block`}
                    >
                      {selectedTaskerData?.package_id === 1
                        ? "NEW WORKER"
                        : " PREMIUM"}
                    </p>
                  </div>
                </div>

                {/* <p className="text-sm sm:text-base md:text-lg font-bold text-textColorOne">
                  25$ /Hr
                </p> */}
              </div>

              <div>
                <p className="text-xs font-normal text-textColorTwo">
                  {selectedTaskerData?.work_title}
                </p>
                <div className="flex flex-row">
                  <LocationIconFull />
                  <p className="text-xs font-semibold text-textColorOne">
                    {[
                      selectedTaskerData?.state,
                      selectedTaskerData?.suberb,
                      selectedTaskerData?.postal_code,
                    ]
                      .filter(Boolean)
                      .join(", ")}{" "}
                  </p>
                </div>

                {selectedTaskerData?.language !== "" ||
                  selectedTaskerData?.language !== null ||
                  (selectedTaskerData?.language !== undefined && (
                    <div className="flex flex-row">
                      <LanguageFullIcon />
                      <p className="text-xs font-semibold text-textColorOne">
                        {selectedTaskerData?.language}
                      </p>
                    </div>
                  ))}
              </div>

              <Row className="flex flex-row gap-3 pt-3">
                {selectedTaskerData?.has_verified === 1 && (
                  <Col className="md:hidden flex flex-row gap-1 justify-start items-center">
                    <VerifyIcon />
                    <p className="text-sm font-medium text-textColorTwo">
                      Verified Worker
                    </p>
                  </Col>
                )}

                {selectedTaskerData?.rate !== 0 && (
                  <Col className="flex flex-row gap-1 justify-start items-center">
                    <ViewsIcon />
                    <p className="text-sm font-medium text-textColorTwo">
                      <span className="text-sm font-bold text-textColorOne">
                        {" "}
                        {selectedTaskerData?.rate}
                      </span>{" "}
                      ({selectedTaskerData?.review_count} Reviews)
                    </p>
                  </Col>
                )}
                {selectedTaskerData?.job_count !== 0 && (
                  <Col className="flex flex-row gap-1 justify-start items-center">
                    <CompleteIcon />
                    <p className="text-sm font-medium text-textColorTwo">
                      <span className="text-sm font-bold text-textColorOne">
                        {" "}
                        {selectedTaskerData?.job_count}+
                      </span>{" "}
                      Completed Tasks
                    </p>
                  </Col>
                )}
              </Row>

              <Row className="flex flex-row gap-1 mt-2">
                {selectedTaskerData?.job_types?.map((item) => {
                  return (
                    <Col>
                      <p className="border-[1px] border-gray-300 py-1 px-2 rounded-full text-xs font-normal text-primaryDarkest">
                        {item?.job_type}
                      </p>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>

          <Divider className="my-3" />

          <div>
            <p className="text-sm font-bold text-textColorOne">
              Skills & Experience
            </p>
            {selectedTaskerData?.work_description === null ||
            selectedTaskerData?.work_description === "" ? (
              <p className="text-sm font-bold text-textColorTwo">_</p>
            ) : (
              <p className="text-xs font-normal text-textColorTwo">
                {selectedTaskerData?.work_description}
              </p>
            )}
          </div>

          <Divider className="my-3" />

          <div className="flex justify-center items-center">
            <WorkSampleImageList
              workSamples={selectedTaskerData?.portfolios?.map(
                (item) => item?.image
              )}
              listSize="small"
            />
          </div>

          <Divider className="my-3" />

          <div className="mt-5">
            <p className="text-sm font-bold text-textColorOne">
              Reviews ({selectedTaskerData?.rates?.length})
            </p>

            {selectedTaskerData?.rates?.length === 0 ? (
              <p className="text-sm font-bold text-textColorTwo">_</p>
            ) : (
              <div>
                {selectedTaskerData?.rates?.map((item) => {
                  return (
                    <div className="pt-3 flex flex-row gap-3 w-full">
                      <div className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                        {item?.profile_picture === null ? (
                          <UserColorProfile
                            name={
                              decryptSecureData(item?.customer_name) || "N Z"
                            }
                            color={"#3bb96e"}
                            size="40px"
                            textSize="16px"
                          />
                        ) : (
                          <img
                            src={item?.profile_pictur}
                            alt=""
                            className="flex w-[32px] h-[32px] rounded-full border-[2px]"
                          />
                        )}
                      </div>

                      <div className="w-full">
                        <div className="flex flex-row justify-between items-center w-full">
                          <p className="text-xs font-semibold">
                            {decryptSecureData(item?.customer_name)}{" "}
                            <span className="font-normal text-textColorTwo">
                              {getDateDifference(item?.rated_date_time)}
                            </span>
                          </p>

                          <div className="flex flex-row gap-2">
                            <p className="text-xs font-semibold">
                              {item?.rate}
                            </p>
                            <div className="flex flex-row gap-1">
                              {Array.from({ length: item?.rate }, (_, i) => (
                                <ViewsIcon key={i} />
                              ))}
                            </div>
                          </div>
                        </div>

                        <p className="text-xxs font-normal text-textColorTwo">
                          {item?.message}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default VIewTaskerProfile;
