import React, { useContext, useEffect, useState } from "react";
import { Carousel, ConfigProvider } from "antd";
import Cleaner from "../../assets/img/cleaning.png";
import Camera from "../../assets/img/camera.png";
import Banking from "../../assets/img/baking.png";
import Cosmetics from "../../assets/img/cosmetics.png";
import Handyman from "../../assets/img/handyman.png";
import Painter from "../../assets/img/painter.png";
import { NotificationContext } from "../../context/NotificationContext";
import { CreateJobPostContext } from "../../context/CreateJobPostContext";
import CreateJobPostServices from "../../services/CreateJobPostServices";

const ServiceCarousel = () => {
  const { openNotification, handleError } = useContext(NotificationContext);
  const {
    setSearchValue,
    setSerchDropDownData,
    setSearchDropdownIsOpen,
    selectedCategoryID,
    setSelectedCategoryID,
    searchRef,

    selectedCategoryDescription,
    setSelectedCategoryDescription,
  } = useContext(CreateJobPostContext);

  const { getAllJobCategories, getRelatedSearchedJobTypes } =
    CreateJobPostServices();

  const [services, setServices] = useState([
    {
      job_category_id: 1,
      job_category: "Cleaning",
      image_path: Cleaner,
      image_hr_path: Cleaner,
      description: "",
    },
    {
      job_category_id: 2,
      job_category: "Handyman",
      image_path: Handyman,
      image_hr_path: Handyman,
      description: "",
    },
    {
      job_category_id: 3,
      job_category: "Photography",
      image_path: Camera,
      image_hr_path: Camera,
      description: "",
    },
    {
      job_category_id: 4,
      job_category: "Painting",
      image_path: Painter,
      image_hr_path: Painter,
      description: "",
    },
    {
      job_category_id: 5,
      job_category: "Makeup",
      image_path: Cosmetics,
      image_hr_path: Cosmetics,
      description: "",
    },
    {
      job_category_id: 6,
      job_category: "Baking",
      image_path: Banking,
      image_hr_path: Banking,
      description: "",
    },
  ]);

  useEffect(() => {
    fetchAllJobCategories();
  }, []);

  const fetchAllJobCategories = async () => {
    try {
      const response = await getAllJobCategories();
      if (response?.data?.success) {
        setServices(response?.data?.output);
      } else {
        openNotification("warning", response?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleGetRelatedJobTypes = async (e) => {
    try {
      const response = await getRelatedSearchedJobTypes({
        search: e?.job_category,
        job_category_id: e?.job_category_id,
      });
      if (response?.data?.success) {
        setTaskerListData(response?.data?.output);
      } else {
        openNotification("warning", response?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const setTaskerListData = (items) => {
    var taskerListObject = [];
    items?.forEach((item) => {
      let data = {
        value: item.job_type_id,
        label: (
          <p className="flex flex-row gap-1 justify-start items-center">
            {item.job_type}
            {item?.similar_job_type !== "-" && (
              <p className="text-base text-textColorZero">
                ({item?.similar_job_type})
              </p>
            )}
          </p>
        ),
      };
      taskerListObject.push(data);
    });
    setSerchDropDownData(taskerListObject);
  };
  return (
    <div className="max-w-[1000px] mx-auto">
      <p className="text-center text-sm md:text-base font-normal text-textColorTwo">
        Click on a service below to find related job types
      </p>
      <ConfigProvider
        theme={{
          components: {
            Carousel: {
              arrowOffset: -1,
              arrowSize: 16,
              dotActiveWidth: 0,
              dotWidth: 0,
              color: "rgb(159,69,69)",
            },
          },
        }}
      >
        <Carousel
          arrows={true}
          dots={false}
          infinite={false}
          draggable={true}
          dotPosition="top"
          className="overflow-hidden"
          // prevArrow={<LeftOutlined className="text-black" />}
          // nextArrow={<RightOutlined className="text-black" />}
          responsive={[
            {
              breakpoint: 5000,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 648,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {services.map((service, index) => (
            <div
              key={index}
              className="p-4 cursor-pointer"
              onClick={() => {
                setSelectedCategoryID(service?.job_category_id);
                setSelectedCategoryDescription(service?.description);
                setSearchValue(" ");
                handleGetRelatedJobTypes(service);
                setSearchDropdownIsOpen(true);
                searchRef.current.focus();
              }}
            >
              <div
                className={`bg-white rounded-lg shadow-lg flex flex-col justify-center items-center p-4 lg:h-[128px] lg:w-[128px] hover:border-[2px] hover:border-primaryDark ${
                  selectedCategoryID === service?.job_category_id &&
                  "border-[2px] border-primaryDark"
                }`}
              >
                <img
                  src={
                    selectedCategoryID === service?.job_category_id
                      ? service?.image_hr_path
                      : service?.image_path
                  }
                  alt={service?.job_category}
                  className="h-9 mb-2"
                />
                <p
                  className={`text-center text-[13px] ${
                    selectedCategoryID === service?.job_category_id
                      ? "text-primaryDark font-medium"
                      : "text-black font-normal"
                  } truncate max-w-full`}
                >
                  {service?.job_category}
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </ConfigProvider>

      <p className="text-center text-sm md:text-base font-normal text-textColorTwo">
        {selectedCategoryDescription}
      </p>
    </div>
  );
};

export default ServiceCarousel;
